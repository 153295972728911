import React, { FC } from 'react';
import classnames from 'classnames';

import { Container, DangerouslySetInnerHtml } from 'layout';
import { ISection } from './model';
import './Section.scss';

const Section: FC<ISection> = ({ name, color, intro, imageAlignment, children, classes }) => {
  const bgColor = color?.label || 'white';

  return (
    <div
      data-testid={`${name}-section`}
      className={classnames('section', classes, `${name}-section`, `section--bg-${bgColor}`, {
        [`section--image-${imageAlignment?.[0]}`]: imageAlignment?.[0],
        [`section--dark-theme`]: color?.label === 'blue',
      })}
    >
      <Container fluid>
        {intro ? <DangerouslySetInnerHtml className="section__intro" html={intro} /> : null}
        {children}
      </Container>
    </div>
  );
};

export default Section;
