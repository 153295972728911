import React, { FC, useEffect } from 'react';
import { graphql } from 'gatsby';
import { UmbracoContactFormSection } from '@shared/types/umbraco';
import { GlobalContactForm } from '@phx-husky/global-contact-form';

import {
  replacePropertyPlaceholder,
  FORM_NAME_PLACEHOLDER,
  CONTACT_FORM_NAME,
  IFRAME_MESSAGE_FORM_SUBMITTED,
  emitGtmEvent,
} from 'utils/gtmEvents';

import './ContactForm.scss';

const ContactForm: FC<UmbracoContactFormSection.IData> = ({ link, gtmFormSubmitEvent }) => {
  useEffect(() => {
    function iframeMessageHandler(e: MessageEvent) {
      if (e.data.message === IFRAME_MESSAGE_FORM_SUBMITTED) {
        gtmFormSubmitEvent &&
          emitGtmEvent(
            replacePropertyPlaceholder(gtmFormSubmitEvent, CONTACT_FORM_NAME, FORM_NAME_PLACEHOLDER)
          );
      }
    }

    window.addEventListener('message', iframeMessageHandler);

    return () => window.removeEventListener('message', iframeMessageHandler);
  }, []);

  return link ? (
    <div className="contact-form" data-testid="contact-form">
      <GlobalContactForm title={CONTACT_FORM_NAME} id="phx-global-form-gatsby" src={link} />
    </div>
  ) : null;
};

export const query = graphql`
  fragment FragmentContactForm on TContactFormSection {
    properties {
      link
      script
    }
    structure
  }
`;

export default ContactForm;
