import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Container, DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';
import GatsbyImage from 'gatsby-theme-husky/src/common/GatsbyImage';
import BreadCrumbs from 'gatsby-theme-husky/src/common/BreadCrumbs';
import { SimplepageTypes } from '@shared/types/umbraco/content';
import classNames from 'classnames';

import './SimpleBanner.scss';

const SimpleBanner: FC<SimplepageTypes.ISimpleBanner> = ({
  description,
  title,
  image,
  breadCrumbs = [],
  titleSize,
  titleSizeMobile,
  imageHelper,
  breadCrumbSeparatorIcon,
  breadCrumbPopIndex,
  breadCrumbIsHideLastSeparator,
  useTextBannerStyle,
}) => {
  const titleSizeClass = titleSize?.[0] || 'large';
  const titleSizeClassMobile = titleSizeMobile?.[0] || 'large';
  const imageHelperSrc = imageHelper?.[0]?.properties;

  return (
    <div
      className={classNames('simple-banner', {
        'simple-banner__with-image': image?.fallbackUrl,
        'simple-banner__with-imageHelper': imageHelperSrc?.image,
      })}
      data-testid="simple-banner"
    >
      <Container fluid>
        <div className="simple-banner__grid">
          <div className="simple-banner__description">
            {breadCrumbs?.length ? (
              <BreadCrumbs
                data={breadCrumbs}
                breadCrumbSeparatorIcon={breadCrumbSeparatorIcon}
                popIndex={breadCrumbPopIndex}
                isHideLastSeparator={breadCrumbIsHideLastSeparator}
                useTextStyle={useTextBannerStyle}
              />
            ) : null}
            {title ? (
              <DangerouslySetInnerHtml
                element="h1"
                className={classNames('simple-banner__description-title', {
                  [`simple-banner__description-title--${titleSizeClass}`]: titleSizeClass,
                  [`simple-banner__description-title--${titleSizeClassMobile}-m`]:
                    titleSizeClassMobile,
                  [`simple-banner__description-title-bg--${useTextBannerStyle?.[0]}`]:
                    useTextBannerStyle?.[0],
                })}
                html={title}
              />
            ) : null}
            {description ? (
              <DangerouslySetInnerHtml
                className="simple-banner__description-text"
                html={description}
              />
            ) : null}
            {imageHelperSrc?.image ? (
              <div className="simple-banner__description-image">
                <GatsbyImage
                  image={imageHelperSrc.image}
                  alt={imageHelperSrc.imageAlt}
                  objectFit="contain"
                  isLazyLoading={false}
                  fadeIn={false}
                  useFocalPoint
                />
              </div>
            ) : null}
          </div>
          {image?.fallbackUrl ? (
            <div className="simple-banner__image">
              <GatsbyImage
                image={image}
                className="simple-banner__image-img"
                alt=""
                role="presentation"
                isLazyLoading={false}
                fadeIn={false}
                useFocalPoint
              />
            </div>
          ) : null}
        </div>
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentSimpleBanner on TSimpleBanner {
    properties {
      description
      title
      titleSize
      titleSizeMobile
      image {
        ...FragmentGatsbyProps
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      imageHelper {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
    }
  }
  fragment FragmentSimpleBannerBigImage on TSimpleBanner {
    properties {
      description
      title
      titleSize
      titleSizeMobile
      image {
        ...FragmentGatsbyProps
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      imageHelper {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
    }
  }
`;

export default SimpleBanner;
